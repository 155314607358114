<template>
  <el-container>
    <el-image src="/images/walking.gif"></el-image>
  </el-container>
</template>

<style lang="scss" scoped>
.el-image {
  margin: 0 auto;
}
</style>